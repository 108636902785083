<template>
  <a-modal style="top: 8px" :width="1000" v-model="visible" :footer="null" :title="'余额明细'" :maskClosable="false">
    <!-- 查询表单 -->
    <a-form-model ref="searchForm" layout="horizontal" :label-col="{ xs: 6 }" :wrapper-col="{ xs: 18 }">
      <a-row :gutter="8">
        <a-col :md="8" :xl="8" :xxl="8" :sm="12">
          <a-form-model-item label="关联单号">
            <a-input v-model="searchData.dealNo" placeholder="请输入关联单号"></a-input>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="12">
          <a-form-model-item label="交易类型">
            <DictSelect
              field="dealType"
              :value.sync="searchData.dealType"
              style="width: 100%"
              placeholder="请选择交易状态"
            ></DictSelect>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="12">
          <a-form-model-item label="金额类型">
            <DictSelect
              field="amountType"
              :value.sync="searchData.amountType"
              style="width: 100%"
              placeholder="请选择金额状态"
            ></DictSelect>
          </a-form-model-item>
        </a-col>
        <a-col :md="8" :xl="8" :xxl="8" :sm="12">
          <a-form-model-item label="交易时间">
            <DateRange :startTime.sync="searchData.startDate" :endTime.sync="searchData.endDate"></DateRange>
          </a-form-model-item>
        </a-col>
        <a-col :xxl="6" :xl="5" :md="8" sm="2">
          <a-form-model-item :label-col="{ span: 0 }" :wrapper-col="{ span: 24 }">
            <a-button type="primary" @click="getList()" icon="search">查询</a-button>
            <a-button type="default" @click="reset()" icon="sync">重置</a-button>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <a-table
      :columns="columns"
      :data-source="tableData"
      :pagination="page"
      @change="changeTable"
      :rowKey="(record) => record.id"
      bordered
      :maskClosable="false"
    >
      <span slot="dealType" slot-scope="text">
        <a-tag color="" v-if="text == 1">支付</a-tag>
        <a-tag color="" v-if="text == 3">批付</a-tag>
      </span>
      <span slot="amountType" slot-scope="text">
        <span v-if="text == 1"><a-tag color="green"> 全部商品 </a-tag></span>
        <span v-if="text == 2"><a-tag color="cyan"> 分类商品 </a-tag></span>
        <span v-if="text == 3"><a-tag color="blue"> 指定商品 </a-tag></span>
      </span>

      <span slot="amount" slot-scope="text"> {{ text | formatMoney }} </span>
      <span slot="dealAmount" slot-scope="text, row">
        <span style="color: red" v-if="row.amountType == 2">(-) </span>
        <span style="color: green" v-if="row.amountType == 1">(+) </span>
        {{ text | formatMoney }}
      </span>
      <span slot="balance" slot-scope="text"> {{ text | formatMoney }} </span>
      <span slot="dealNo" slot-scope="text, row">
        <a @click="checkOrderInfo(row.dealNo, row.dealType)">{{ text }}</a>
      </span>
    </a-table>

    <div style="text-align: right; margin-top: 14px">
      <a-button type="default" @click="toCancel">关闭</a-button>
      <!--      <a-button type="primary" @click="toSubmit">确认</a-button>-->
    </div>
    <OrderInfoCheckModal ref="OrderInfoCheckModal"></OrderInfoCheckModal>
    <OrderShipInfoEditModal ref="OrderShipInfoEditModal"></OrderShipInfoEditModal>
  </a-modal>
</template>

<script>
import OrderShipInfoEditModal from '@/views/order_ship_info/components/OrderShipInfoEditModal'
import OrderInfoCheckModal from '@/views/order_info/components/OrderInfoCheckModal'
const qs = require('qs')

const pageSource = {
  current: 1,
  pageSize: 10,
  total: 0,
}
export default {
  name: 'DealerBalanceDetailed',
  components: {
    OrderInfoCheckModal,
    OrderShipInfoEditModal,
  },
  data() {
    return {
      columns: [
        {
          title: '交易时间',
          dataIndex: 'dealDate',
          align: 'center',
          // width: 220,
          key: 'dealDate',
        },

        {
          title: '交易前额度（元）',
          dataIndex: 'amount',
          align: 'center',
          key: 'amount',
          // width: 150,
          scopedSlots: { customRender: 'amount' },
        },
        {
          title: '交易额度（元）',
          dataIndex: 'dealAmount',
          align: 'left',
          key: 'dealAmount',
          // width: 150,
          scopedSlots: { customRender: 'dealAmount' },
        },
        {
          title: '交易后额度（元）',
          dataIndex: 'balance',
          align: 'center',
          key: 'balance',
          // width: 150,
          scopedSlots: { customRender: 'balance' },
        },
        {
          title: '交易类型',
          dataIndex: 'dealType',
          align: 'center',
          // width: 100,
          key: 'dealType',
          scopedSlots: { customRender: 'dealType' },
        },
        {
          title: '额度类型',
          dataIndex: 'amountType',
          align: 'center',
          // width: 100,
          key: 'amountType',
          scopedSlots: { customRender: 'amountType' },
        },
        {
          title: '单号',
          dataIndex: 'dealNo',
          align: 'center',
          // width: 300,
          key: 'dealNo',
          scopedSlots: { customRender: 'dealNo' },
        },
      ],
      visible: false,
      tableData: [],
      searchData: {}, // 搜索条件
      scroll: { y: 600 },
      tableLoading: false, //表格loading
      page: {
        current: 1,
        pageSize: 10,
        total: 0,
        showSizeChanger: true,
        showTotal: () => `共${this.page.total}条`,
      },
      selectedRowKeys: [],
      baseUrl: process.env.VUE_APP_API_BASE_URL,
      selectedRows: [],
      dealerId: 0,
      title: '',
    }
  },
  methods: {
    // 查询
    getList() {
      this.page.current = 1
      this.page.pageSize = 10
      this.getData()
    },

    // 重置
    reset() {
      this.page.current = 1
      this.page.pageSize = 10
      this.searchData = {}
      this.regionalOffice = []
      this.getData()
    },

    //打开上游单据
    checkOrderInfo(orderCode, dealType) {
      console.log(orderCode, dealType)
      const data = {
        orderCode: orderCode,
      }
      if (dealType == 3) {
      } else {
        if (orderCode.indexOf('DO') !== -1) {
          this.$refs.OrderShipInfoEditModal.setRowDataByCode(data, 'check')
        } else {
          this.$refs.OrderInfoCheckModal.isShowByOrderCode(data, 'check')
        }
      }
    },
    setRowData(row) {
      this.visible = true
      this.dealerId = row.dealerId
      this.title = row.dealerName
      this.getData()
    },

    getData() {
      let params = {
        dealerId: this.dealerId,
        pageSize: this.page.pageSize,
        pageNumber: this.page.current,
      }
      const postData = Object.assign(params, this.searchData)
      // this.axios.get('/api/dealer/dealer/dealerPayRecord/dealerById', {params}).then(res => {
      this.axios
        .get(
          `/api/dealer/dealer/dealerPayRecord/selectPayRecordByDealer?${qs.stringify(postData, {
            arrayFormat: 'repeat',
          })}`
        )
        .then((res) => {
          if (res.code === 200) {
            const { total, records } = res.body
            this.page.total = total
            this.tableData = records
          }
        })
    },

    // table分页
    changeTable(pagination) {
      this.page = pagination
      this.getData()
    },

    toCancel() {
      this.visible = false
    },
  },
}
</script>

<style scoped>
</style>